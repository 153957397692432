/**
 * External Dependencies
 */
import 'jquery'; // jQuery

import AOS from 'aos'; // Animate On Scroll
import SmoothScrollTop from 'smooth-scroll-top'; // Smooth Scroll Top
import SimpleParallax from "simple-parallax-js"; // Simple Parallax

/* import Filterizr */
import Filterizr from "filterizr";
import Isotope from 'isotope-layout/dist/isotope.pkgd';

Filterizr.installAsJQueryPlugin($);

import './components/responsive';

import Swiper from 'swiper/bundle'; // Swiper JS
import 'swiper/css/bundle'; // import Swiper styles

/* import mmenu + menu */
import 'mmenu-js';
import './components/menu';
import simpleParallax from "simple-parallax-js";

$(() => {
  /**
   * WP Evo Theme version
   */
  console.log('- 💎 WP Evo Theme v0.0.1 - Made with 💗 - ©️ S2i Evolution');

  //////////////////////
  // AXEPTIO //
  /////////////////////

  window._axcb = window._axcb || [];
  window._axcb.push(function(sdk){

    console.log('axeptio SDK - loaded');

    $('.axeptio').on('click touch', function(){
      sdk.openCookies();
    });
  });

  ///////// Init AOS  /////////
  AOS.init({
    //disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    //disable: 'mobile',
    once: true,
    animatedClassName: 'aos-animate', // class applied on animation
  });
  console.log('[lib] > Animate On Scroll loaded');

  ///////// Init Smooth Scroll Top  /////////
  const scrollTop = new SmoothScrollTop({
    position: 'right',
    bgColor: '#DC5538',
  });
  scrollTop.init();
  console.log('[lib] > Smooth Scroll Top loaded');

  check_header();
  $(window).on("scroll", check_header);
  $(window).on("resize", check_header);

  ///////// Init Simple Parallax  /////////
  const simpleParallax = require('simple-parallax-js');

  if($(window).width() > 	320) {

    var image = document.getElementsByClassName('thumbnail');
    new simpleParallax(image, {
      orientation: 'right',
      transition: 'cubic-bezier(0,0,0,1)',
      overflow: true
    });

    var image_left = document.getElementsByClassName('thumbnail-left');
    new simpleParallax(image_left, {
      orientation: 'left',
      delay: .6,
      transition: 'cubic-bezier(0,0,0,1)',
      overflow: true
    });

    var image_scroll = document.getElementsByClassName('thumbnail-scroll');
    new simpleParallax(image_scroll, {
      delay: .6,
      transition: 'cubic-bezier(0,0,0,1)'
    });

    var image_scroll2 = document.getElementsByClassName('thumbnail-img');
    new simpleParallax(image_scroll2, {
      delay: .6,
      transition: 'cubic-bezier(0,0,0,1)',
      overflow: true
    });

  }

  /// SCROLL ///
  // $('a[href^="#"]').not('.menu-trigger').addClass("scroll");

  $('.scroll').on('click touch', function () { // Au clic sur un élément
    scrollTo(this.hash, 750);
  });

  //$(document).on("scroll", onScroll);

  ///// CLIC SUR LES CARDS /////
  $('.card').on('click', function () {
    if( $(this).find('a').attr('target') == '_blank'){
      window.open($(this).find('a').attr('href'), '_blank');
    } else {
      document.location.href = $(this).find('a').attr('href');
    }
  });

  ///////// INIT ISOTOPE  /////////

  if ($('body').hasClass('page-template-page-portfolio')) {

    var $grid = $('.filter-container').isotope({
      itemSelector: '.filtr-item',
      layoutMode: 'fitRows',
      initLayout: false
    });

    $grid.isotope( 'on', 'arrangeComplete', function() {
      $('.filter-container').animate({
        opacity: 1
      }, 1000);
    });

    $grid.isotope();

    var filterFns = {
      // show if number is greater than 50
      numberGreaterThan50: function() {
        var number = $(this).find('.number').text();
        return parseInt( number, 10 ) > 50;
      },
      // show if name ends with -ium
      ium: function() {
        var name = $(this).find('.name').text();
        return name.match( /ium$/ );
      }
    };
    // bind filter button click
    $('.filter_controls').on( 'touch click', 'button', function() {
      var filterValue = $( this ).attr('data-filter');
      // use filterFn if matches value
      filterValue = filterFns[ filterValue ] || filterValue;
      $grid.isotope({ filter: filterValue });
    });
    $('.filter_controls').each( function( i, buttonGroup ) {
      var $buttonGroup = $( buttonGroup );
      $buttonGroup.on( 'click', 'button', function() {
        $buttonGroup.find('.btn-active').removeClass('btn-active');
        $( this ).addClass('btn-active');
      });
    });

    // recherche du paramètre filter dans l'url
    // appliquer sur une URL de ce type : /nos-realisations/?f=valeur_a_filtrer
    window.$_GET = new URLSearchParams(location.search);
    var f = $_GET.get('f');

    if (f) {
      /* AJOUT ANTHONY 19/05/2022 */
      $('.filter_controls button').removeClass('btn-active');
      $('.filter_controls button[data-filter=".' + f + '"]').addClass('btn-active');
      $grid.isotope({ filter: "." + f });
    }

    // trigger les classes sur les options de filtres
    $('.filter_controls button').click(function () {
      var filter = $(this).data('filter');
      if (filter === 'all') {
        $('.filter_controls button').removeClass('btn-active');
        $(this).addClass('btn-active');
        $grid.isotope({ filter: '*' });
      } else {
        $('.filter_controls button').removeClass('btn-active');
        $(this).toggleClass('btn-active');
      }

      let base_url = new URL(window.location.href);
      let params = new URLSearchParams(base_url.search);

      params.append('f', filter);

    });

  }


  ///////// INIT FILTERIZR  /////////

  // if ($('body').hasClass('page-template-page-portfolio')) {
  //   // Adjust the CSS selector to match the container where
  //   // you set up your image gallery
  //   var filterMulti = $('.filter-container').filterizr({
  //     setupControls: true,
  //     gutterPixels: 15,
  //     layout: 'sameSize',
  //   });
  //
  //   console.log('[lib] > Filterizr loaded');
  //
  //   // recherche du paramètre filter dans l'url
  //   // appliquer sur une URL de ce type : /nos-realisations/?f=valeur_a_filtrer
  //   window.$_GET = new URLSearchParams(location.search);
  //   var f = $_GET.get('f');
  //
  //   if (f) {
  //     /* AJOUT ANTHONY 19/05/2022 */
  //     $('.filter_controls button').removeClass('btn-active');
  //     $('.filter_controls button[data-filter="' + f + '"]').addClass('btn-active');
  //     filterMulti.filterizr('filter', f);
  //   }
  //
  //   // trigger les classes sur les options de filtres
  //   $('.filter_controls button').click(function () {
  //     var filter = $(this).data('filter');
  //     if (filter === 'all') {
  //       $('.filter_controls button').removeClass('btn-active');
  //       $(this).addClass('btn-active');
  //       filterMulti.filterizr('filter', 'all');
  //     } else {
  //       $('.filter_controls button').removeClass('btn-active');
  //       $(this).toggleClass('btn-active');
  //     }
  //
  //     let base_url = new URL(window.location.href);
  //     let params = new URLSearchParams(base_url.search);
  //
  //     params.append('f', filter);
  //
  //   });
  //
  //   console.log('[lib] > Filterizr loaded');
  //
  // }


///// SWIPE JS /////
  const slider = new Swiper('.mySwiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // Responsive
    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
    mousewheel: false,
    keyboard: true,
  });

  var swiper = new Swiper(".mySwiperlogos", {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      reverseDirection: false,
      waitForTransition: false,
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // Responsive
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      1536: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
    },
  });

  var swiper = new Swiper(".mySwipertestimonials", {
    slidesPerView: 1,
    spaceBetween: 30,
    autoplay: {
      delay: 20000,
      disableOnInteraction: false,
      reverseDirection: false,
      waitForTransition: false,
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // Responsive
    breakpoints: {
      576: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      1980: {
        slidesPerView: 4.5,
        spaceBetween: 30,
      },
    },
  });


  const sliderNumbers = new Swiper('.mySwiperNumbers', {
    slidesPerView: 2,
    spaceBetween: 20,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // Responsive
    breakpoints: {
      576: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    mousewheel: false,
    keyboard: true,
  });

  $('.customSwiper').each( function(i) {

    var el = $(this);
    var parent = el.parent();
    var pagi = $(".swiper-pagination")[i];
    var pagiNext = $(".swiper-button-next")[i];
    var pagiPrev = $(".swiper-button-prev")[i];

    if(el.data('dispo') == 3) {
      var customBreak = {
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      };
    }

    if(el.data('dispo') == 6) {
      var customBreak = {
        120: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1536: {
          slidesPerView: 6,
          spaceBetween: 40,
        },
      };
    }

    // if(el.data('dispo') == 5) {
    //   var customBreak = {
    //     120: {
    //       slidesPerView: 2,
    //       spaceBetween: 20,
    //     },
    //     576: {
    //       slidesPerView: 5,
    //       spaceBetween: 20,
    //     },
    //     // 1536: {
    //     //   slidesPerView: 5,
    //     //   spaceBetween: 40,
    //     // },
    //   };
    // }

    if(el.data('dispo') == 4) {
      var customBreak = {
        576: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1536: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      };
    }


    var customSlider = new Swiper(el[0], {
      slidesPerView: 1,
      spaceBetween: 10,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
        reverseDirection: false,
        waitForTransition: false,
      },
      // loop: true,
      pagination: {
        el: pagi,
        dynamicBullets: true,
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive
      breakpoints: customBreak,
      mousewheel: false,
      keyboard: true,
    });
  });



///// CONTENU ACCORDEON COLLASPE /////
  $('.collapse-click').on('click touch', function(){
    $(this).toggleClass('open');
    $(this).next('.tab-content').slideToggle('fast')
  });


});

function scrollTo(page, speed) {
  $('html, body').animate({ scrollTop: $(page).offset().top - $('header').height() }, speed); // Go
  return false;
}

///// FIXED HEADER /////
function check_header() {
  if ($(window).scrollTop() < 1 && $(window).width() > 320) {
    $("header").removeClass("second_style");
  }
  if ($(window).scrollTop() >= 1 || $(window).width() < 320) {
    $("header").addClass("second_style");
  }
}


