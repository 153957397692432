document.addEventListener(
  "DOMContentLoaded", () => {


    $(".menu-item-has-children").each(function(){
      $(this).children().first().replaceWith("<span>"+$(this).children().first().text()+"</span>");
    });


    var MENU = new Mmenu("#my-menu", {
      extensions: ["position-front"],
      "offCanvas": {
        "position": "right-front",
      },
      navbar: {
        add: false,
      },
      navbars: [{
        "position": "top",
        "content": [
          "<div class='top-menu-content'><div class='menu-logo'></div></div>",
        ]
      }],
      "slidingSubmenus": true,
    }, {
      offCanvas: {
        clone: true,
        page: {
          selector: "#app",
        },
      }
    });

    // $('.mm-listitem a').on('click touch', function() {
    //   MENU.close();
    // });

    const api = MENU.API;

    api.bind('open:after', function () {
      $('.menu-btn').addClass('open');
    })

    api.bind('close:after', function () {
      $('.menu-btn').removeClass('open');
    })

  }
);
