import enquire from "enquire-js";

const md_max = "screen and (max-width:1024px)";
const md_min = "screen and (min-width:1024px)";

enquire.register(md_min, {
  setup : function() {
    // Load in content via AJAX (just the once)
  },
  match : function() {
    // Show sidebar
  },
  unmatch : function() {
    // Hide sidebar
  }
});

enquire.register(md_max, {

  // OPTIONAL
  // If supplied, triggered when a media query matches.
  match : function() {
    console.log('OUI')
  },

  // OPTIONAL
  // If supplied, triggered when the media query transitions
  // *from a matched state to an unmatched state*.
  unmatch : function() {
    console.log('NON')
  },

  // OPTIONAL
  // If supplied, triggered once, when the handler is registered.
  setup : function() {},

  // OPTIONAL, defaults to false
  // If set to true, defers execution of the setup function
  // until the first time the media query is matched
  deferSetup : true,

  // OPTIONAL
  // If supplied, triggered when handler is unregistered.
  // Place cleanup code here
  destroy : function() {}

});

enquire.register(md_max, {

  // OPTIONAL
  // If supplied, triggered when a media query matches.
  match : function() {
    console.log('OUI')
  },

  // OPTIONAL
  // If supplied, triggered when the media query transitions
  // *from a matched state to an unmatched state*.
  unmatch : function() {
    console.log('NON')
  },

  // OPTIONAL, defaults to false
  // If set to true, defers execution of the setup function
  // until the first time the media query is matched
  deferSetup : true,

});
